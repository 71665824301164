import './style.css';
import {Map, View} from 'ol';
import TileLayer from 'ol/layer/Tile';
import OSM from 'ol/source/OSM';
import {FullScreen, defaults as defaultControls} from 'ol/control.js';
import {ScaleLine} from 'ol/control.js';

const view = new View({
	projection: 'EPSG:4326',
    center: [119.43, -2],
    zoom: 5,
});

/*const scaleControl = new ScaleLine({
  units: 'metric',
  bar: true,
  steps: 4,
  text: true,
  minWidth: 140,
});*/

const map = new Map({
  target: 'map',
  controls: defaultControls().extend([new FullScreen()]),
  layers: [
    new TileLayer({
      source: new OSM({attributions:"© OpenStreetMap"})
    })
  ],
  view: view,
});

//map.addControl(scaleControl);
